import React, { useState } from 'react';
import { Snackbar, Button, Paper, Typography, Box, } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.shadows[2],
  minWidth: '320px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto, sans-serif',
  lineHeight: 1.5,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 16px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CookieNotification = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleDismiss = () => {
    setIsVisible(false);
  };

  return (
    <Snackbar
      open={isVisible}
      onClose={handleDismiss}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <StyledPaper>
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <StyledTypography
            variant="body1"
            sx={{
              fontWeight: 500,
              marginBottom: '4px',
              fontSize: '14px',
            }}
          >
            This site uses cookies to enhance your experience.
          </StyledTypography>
          <StyledTypography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontSize: '12px',
            }}
          >
            By continuing, you agree to our use of cookies.
          </StyledTypography>
        </Box>
        <StyledButton
          size="small"
          variant="contained"
          onClick={handleDismiss}
        >
          Got it
        </StyledButton>
      </StyledPaper>
    </Snackbar>
  );
};

export default CookieNotification;
