import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Tooltip,  Dialog,
  DialogContent,
  DialogActions } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { io } from 'socket.io-client';
import { BitlyClient } from 'bitly';



import { format, parseISO } from 'date-fns';
import edgeSdkPyContent from './edgeSdkPy';
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0DC", // ThingsBoard primary color
    },
    secondary: {
      main: "#C0EAEB", // ThingsBoard secondary color
    },
    // background: {
    //   default: '#F4F6F9', // Light background color
    // },
    // text: {
    //   primary: '#333333', // Dark text color
    // }
  },
});


const styles = {
    button: {
      backgroundColor: '#1E88E5',
      color: '#fff',
      margin: '10px 0',
      textTransform: 'none',
      borderRadius: '4px',
    },
    modalPaper: {
      margin: 'auto',
      padding: '20px',
      width: '80%',
      marginTop: '5%',
      backgroundColor: '#f4f6f8',
      borderRadius: '8px',
    },
    tableContainer: {
      maxHeight: '400px',
      overflowY: 'auto',
      border: '1px solid #e0e0e0',
    },
    iconButton: {
      color: '#1E88E5',
    },
    codeModalPaper: {
      margin: 'auto',
      padding: '20px',
      width: '60%',
      marginTop: '5%',
      backgroundColor: '#f4f6f8',
      borderRadius: '8px',
    },
    codeContainer: {
      maxHeight: '400px',
      overflowY: 'scroll',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      padding: '10px',
      backgroundColor: '#2d2d2d',
    },
  };

const CodeDownloadModal = ({ open, onClose, userId }) => {
  const [data, setData] = useState([]);
  const [selectedCodeContent, setSelectedCodeContent] = useState('');
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showJsonPopup, setShowJsonPopup] = useState(false);
  const [selectedJsonContent, setSelectedJsonContent] = useState('');

  // Establish WebSocket connection on component mount
  // useEffect(() => {
  //   const ws = new WebSocket(`ws://199.199.51.246:8000/ws/user_apps/${userId}/`);
  //   ws.onopen = () => console.log("WebSocket connection established.");
  //   ws.onmessage = (event) => {
  //     const updatedData = JSON.parse(event.data);
  //     setData(updatedData);
  //   };
  //   ws.onerror = (error) => console.error("WebSocket error:", error);
  //   ws.onclose = () => console.log("WebSocket connection closed.");

  //   setSocket(ws);

  //   // Cleanup on component unmount
  //   return () => {
  //     if (socket) {
  //       socket.close();
  //     }
  //   };
  // }, [userId]);
  // useEffect(() => {
  //   console.log("Starting useEffect hook for user apps");
  //   console.log("Fetching user apps...");
  //   const fetchUserApps = async () => {
  //     console.log("Inside fetchUserApps function");
  //     try {
  //       console.log("Making API request to fetch user apps");
  //       const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get/${userId}/`);
  //       console.log("Response received:", response);
  //       if (!response.ok) {
  //         console.log("Response not OK, throwing error");
  //         throw new Error("Failed to fetch user apps");
  //       }
  //       console.log("Parsing response JSON");
  //       const data = await response.json();
  //       console.log("Data fetched successfully:", JSON.stringify(data[6]));
  //       console.log("Sorting data by created_at");
  //       const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //       console.log("Setting sorted data to state");
  //       setData(sortedData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   console.log("Calling initial fetchUserApps");
  //   fetchUserApps();

  //   console.log("Setting up Socket.IO connection");
  //   const socket = io(`${process.env.REACT_APP_PUBLIC_URL}`, {
  //     path: `/ws/user_apps/${userId}/`, // Use Django WebSocket URL
  //     transports: ['websocket', 'polling'], // Try WebSocket first, fallback to polling
  //     reconnection: true,
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000,
  //     timeout: 20000
  //   });

  //   socket.on('connect', () => {
  //     console.log("Socket.IO connection established for user apps updates");
  //   });

  //   socket.on('userAppsUpdate', (data) => {
  //     console.log("Socket.IO update received");
  //     console.log("Socket.IO data received:", data);
  //     console.log("Fetching fresh data due to Socket.IO update");
  //     fetchUserApps();
  //   });

  //   socket.on('connect_error', (error) => {
  //     console.error("Socket.IO connection error:", error);
  //     // Log additional details about the connection attempt
  //     console.log("Connection details:", {
  //       url: socket.io.uri,
  //       path: socket.io.opts.path,
  //       transports: socket.io.opts.transports
  //     });
  //   });

  //   console.log("Setting up cleanup function");
  //   return () => {
  //     console.log("Running cleanup - disconnecting Socket.IO");
  //     if (socket) {
  //       console.log("Socket exists, disconnecting");
  //       socket.disconnect();
  //     }
  //   };
  // }, [userId]);

  // useEffect(() => {
  //   console.log("Starting useEffect hook for user apps");
  //   const fetchUserApps = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get/${userId}/`);
  //       if (!response.ok) throw new Error("Failed to fetch user apps");
  //       const data = await response.json();
  //       const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //       setData(sortedData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  
  //   fetchUserApps();
  
  //   const socket = io(`${process.env.REACT_APP_PUBLIC_URL}`, {
  //     path: `ws/user_apps/${userId}/`,
  //     transports: ['websocket', 'polling'],
  //     reconnection: true,
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000,
  //     timeout: 200000
  //   });
  
  //   socket.on('connect', () => {
  //     console.log("Socket.IO connection established");
  //   });
  
  //   socket.on('userAppsUpdate', () => {
  //     console.log("Socket.IO update received, fetching fresh data");
  //     fetchUserApps();
  //   });
  
  //   socket.on('connect_error', (error) => {
  //     console.error("Socket.IO connection error:", error);
  //     console.log("Connection details:", {
  //       url: socket.io.uri,
  //       path: socket.io.opts.path,
  //       transports: socket.io.opts.transports
  //     });
  //   });
  
  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //     }
  //   };
  // }, [userId]);


  // useEffect(() => {
  //   console.log("Starting useEffect for user apps");

  //   // Function to fetch user applications
  //   const fetchUserApplications = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get/${userId}/`
  //       );
  //       if (!response.ok) throw new Error("Failed to fetch user apps");
  //       const apps = await response.json();
  //       const sortedApps = apps.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //       setData(sortedApps);
  //     } catch (error) {
  //       console.error("Error fetching user apps:", error);
  //     }
  //   };

  //   fetchUserApplications(); // Initial fetch

  //   // Set up WebSocket connection
  //   const socket = io(`${process.env.REACT_APP_PUBLIC_URL}`, {
  //     path: `/ws/user_apps/${userId}/`,
  //     transports: ["websocket"],
  //     reconnection: true,
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000,
  //     timeout: 200000,
  //   });

  //   socket.on("connect", () => {
  //     console.log("WebSocket connected");
  //   });

  //   socket.on("connect_error", (error) => {
  //     console.error("WebSocket connection error:", error);
  //   });

  //   // Listen for updates
  //   socket.on("userAppsUpdate", (message) => {
  //     console.log("WebSocket update received:", message);
  //     setData(message.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
  //   });

  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //       console.log("WebSocket disconnected");
  //     }
  //   };
  // }, [userId]);



  // useEffect(() => {
  //   const fetchUserApps = async () => {
  //     try {
  //       console.log("Fetching user apps...");
  //       const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get/${userId}/`);
  //       console.log("Response received:", response);
        
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch user apps");
  //       }
        
  //       const data = await response.json();
  //       console.log("Data fetched successfully:", JSON.stringify(data[6]));
        
  //       // Sort the data by created_at in descending order (latest first)
  //       const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //       setData(sortedData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   // Fetch user apps immediately on mount
  //   fetchUserApps();

  //   // Set up an interval to fetch user apps every 5 minutes (300000ms)
  //   const intervalId = setInterval(() => {
  //     fetchUserApps();
  //   }, 3000);  

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [userId]);















  

  // useEffect(() => {
  //   console.log("Starting WebSocket connection");

  //   const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  //   const wsUrl = `${protocol}://199.199.51.246:8000/ws/user_apps/${userId}/`;
  //   console.log("WebSocket URL:", wsUrl);

  //   const socket = new WebSocket(wsUrl);

  //   socket.onopen = () => {
  //     console.log("WebSocket connected successfully");
  //   };

  //   // Event: Receive message from server
  //   socket.onmessage = (event) => {
  //     console.log("Message received from WebSocket server:", event.data);
  //     const newData = JSON.parse(event.data);
  //     setData(newData);
  //   };

  //   socket.onerror = (error) => {
  //     console.error("WebSocket Error:", error);
  //   };

  //   // Event: WebSocket closed
  //   socket.onclose = () => {
  //     console.log("WebSocket connection closed");
  //   };

  //   // Cleanup WebSocket connection on component unmount
  //   return () => {
  //     console.log("Cleanup: Closing WebSocket connection");
  //     socket.close();
  //   };
  // }, [userId]);



  useEffect(() => {
    console.log("Fetching user apps...");
    fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get/${userId}/`)
      .then(response => {
        console.log("Response received:", response);
        if (!response.ok) {
          throw new Error("Failed to fetch user apps");
        }
        return response.json();
      })
      .then(data => {
        // console.log("Data fetched successfully:", JSON.stringify(data[6]));
        // Sort the data by created_at in descending order (latest first)
        const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setData(sortedData);
      })
      .catch(error => console.error("Error fetching data:", error));
  }, [userId]);

  const handleCloseCodePopup = () => setShowCodePopup(false);

  const handleViewCode = (codeContent) => {
    setSelectedCodeContent(codeContent);
    setShowCodePopup(true);
  };
  const handleDownload = (content, filename, contentType = "text/plain") => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };

  const handleDownloadCode = (codeContent, packageName) => {
    handleDownload(codeContent, `service_${packageName}.py`, "text/plain");
  };

  const handleDownloadConfig = (configData, packageName) => {
    const jsonContent = JSON.stringify(configData, null, 2);
    handleDownload(jsonContent, `service_${packageName}.json`, "application/json");
  };

  // const handleDownloadZip = (id, packageName) => {
  //   fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/download_pkg/${id}/`)
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.blob();
  //     })
  //     .then(blob => {
  //       if (blob.size === 0) {
  //         throw new Error("Downloaded file is empty");
  //       }
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `${packageName}.zip`;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch(error => {
  //       console.error("Error downloading ZIP:", error);
  //       alert("Error downloading file. Please try again.");
  //     });
  // };

  const handleDownloadZip = async (id, packageName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/download_pkg/${id}/`, {
        method: 'GET',
        // credentials: 'include', // Include credentials like cookies
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data.url) {
        // Open the SharePoint URL in a new tab
        window.open(data.url, '_blank');
      } else {
        throw new Error('Download URL not found in response');
      }

    } catch (error) {
      console.error("Error downloading ZIP:", error);
      alert("Error downloading file. Please try again.");
    }
  };


  
  const handleDownloadDeb = (id, packageName) => {
    console.log(`Starting DEB download for package ${packageName} with ID ${id}`);

    // First fetch the checksum from the backend
    fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/get_by_id/${userId}/${id}/`)
      .then(response => {
        console.log('Received response from get_by_id endpoint');
        return response.json();
      })
      .then(async appData => {
        console.log('Retrieved app data:', appData);
        const storedChecksum = appData.checksum;
        console.log('Stored checksum:', storedChecksum);
        
        // Then download the file
        console.log('Initiating file download...');
        const response = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/download_deb/${id}/`);
        if (!response.ok) {
          console.error('Download response not OK:', response.status);
          throw new Error("Network response was not ok");
        }

        const contentDisposition = response.headers.get('Content-Disposition');
        console.log('Content-Disposition header:', contentDisposition);
        
        let filename;
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/);
          filename = filenameMatch ? filenameMatch[1] : `${packageName}.deb`;
        } else {
          filename = `${packageName}.deb`;
        }
        console.log('Determined filename:', filename);

        const blob = await response.blob();
        console.log('Downloaded blob size:', blob.size);
        return { blob, filename, storedChecksum };
      })
      .then(async ({ blob, filename, storedChecksum }) => {
        if (blob.size === 0) {
          console.error('Downloaded blob is empty');
          throw new Error("Downloaded file is empty");
        }

        console.log('Starting checksum calculation...');
        // Calculate checksum of downloaded file
        const fileReader = new FileReader();
        const checksumPromise = new Promise((resolve, reject) => {
          fileReader.onload = async (event) => {
            try {
              const arrayBuffer = event.target.result;
              console.log('File loaded into ArrayBuffer');
              
              const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
              console.log('Hash buffer generated');
              
              const hashArray = Array.from(new Uint8Array(hashBuffer));
              const downloadedChecksum = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
              console.log('Calculated downloaded checksum:', downloadedChecksum);
              resolve(downloadedChecksum);
            } catch (error) {
              console.error('Error calculating checksum:', error);
              reject(error);
            }
          };
          fileReader.onerror = () => {
            console.error('FileReader error occurred');
            reject(new Error("Error reading file"));
          };
        });

        fileReader.readAsArrayBuffer(blob);
        const downloadedChecksum = await checksumPromise;

        // Verify checksum
        if (storedChecksum) {
          console.log('Comparing checksums...');
          console.log('Stored:', storedChecksum);
          console.log('Downloaded:', downloadedChecksum);
          
          if (storedChecksum.toLowerCase() !== downloadedChecksum.toLowerCase()) {
            console.error('Checksum mismatch detected');
            throw new Error("File integrity check failed - checksums do not match. The downloaded file may be corrupted.");
          }
          console.log('Checksum verification passed');
        } else {
          console.warn('No stored checksum available for verification');
        }

        console.log('Initiating file download to user...');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        console.log('File download completed');

        // Increment download count
        console.log('Incrementing download count...');
        incrementDownloadCount(id);
      })
      .catch(error => {
        console.error("Error in DEB download process:", error);
        alert(`Error downloading file: ${error.message}`);
      });
  };

  

  const handleViewJson = (configData) => {
    setSelectedJsonContent(JSON.stringify(configData, null, 2));
    setShowJsonPopup(true);
  };

  const handleCloseJsonPopup = () => setShowJsonPopup(false);

  const incrementDownloadCount = (id) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/user_apps/increment_download/${id}/`, {
      method: 'POST',
    })
      .then(response => response.json())
      .then(updatedData => {
        setData(prevData => prevData.map(item => 
          item.id === updatedData.id ? updatedData : item
        ));
      })
      .catch(error => console.error("Error incrementing download count:", error));
  };

  const handleDownloadEdgeSdkPy = () => {
    handleDownload(edgeSdkPyContent, "edgeSdkPy.py", "text/plain");
  };
  const bitlyToken = process.env.REACT_APP_BITLY_TOKEN;
  const bitly = new BitlyClient(bitlyToken, {});

  const shortenUrl = async (url) => {
    console.log(url)
    try {
      const response = await bitly.shorten(url);
      return response.link;
    } catch (error) {
      console.error('Error shortening URL:', error);
      return url; 
    }
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Paper 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Typography 
            variant="h5" 
            align="center" 
            gutterBottom
            sx={{
              color: '#00A0DC',
              fontWeight: 500,
              mb: 3
            }}
          >
            User Applications
          </Typography>

          <Button
            variant="contained"
            onClick={handleDownloadEdgeSdkPy}
            startIcon={<DownloadIcon />}
            sx={{
              mb: 3,
              backgroundColor: '#00A0DC',
              '&:hover': {
                backgroundColor: '#0081b0'
              }
            }}
          >
            Download EdgeSDK
          </Button>

          <TableContainer 
            sx={{
              maxHeight: '60vh',
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#C0EAEB',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#00A0DC'
                }
              }
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Sr. No</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Package Name</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Description</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Version</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Created At</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Updated At</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Downloads</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>Code & Config</TableCell>
                  <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>ZIP</TableCell>
                  {/* <TableCell sx={{ backgroundColor: '#C0EAEB', fontWeight: 'bold' }}>DEB</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow 
                    key={item.id}
                    sx={{
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f9f9f9',
                      },
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      }
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell sx={{ fontWeight: 500 }}>{item.package_name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.version}</TableCell>
                    <TableCell>
                      {item.created_at ? format(parseISO(item.created_at), 'MM/dd/yyyy') : "N/A"}
                    </TableCell>
                    <TableCell>
                      {item.updated_at ? format(parseISO(item.updated_at), 'MM/dd/yyyy') : "N/A"}
                    </TableCell>
                    <TableCell>{item.number_of_downloads}</TableCell>
                    <TableCell>
                      <Tooltip title="View Code">
                        <IconButton 
                          onClick={() => handleViewCode(item.code_content)}
                          sx={{ 
                            color: '#00A0DC',
                            '&:hover': { backgroundColor: '#C0EAEB' }
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download Code">
                        <IconButton 
                          onClick={() => handleDownloadCode(item.code_content, item.package_name)}
                          sx={{ 
                            color: '#00A0DC',
                            '&:hover': { backgroundColor: '#C0EAEB' }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Config">
                        <IconButton 
                          onClick={() => handleViewJson(item.config_data)}
                          sx={{ 
                            color: '#00A0DC',
                            '&:hover': { backgroundColor: '#C0EAEB' }
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download Config">
                        <IconButton 
                          onClick={() => handleDownloadConfig(item.config_data, item.package_name)}
                          sx={{ 
                            color: '#00A0DC',
                            '&:hover': { backgroundColor: '#C0EAEB' }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    {/* <TableCell>
                      <Tooltip title="Download ZIP" arrow>
                        <IconButton 
                          onClick={() => {
                            handleDownloadZip(item.id, item.package_name);
                            incrementDownloadCount(item.id);
                          }}
                          sx={{ 
                            color: '#00A0DC',
                            '&:hover': { backgroundColor: '#C0EAEB' }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                    <TableCell>
                      <Tooltip title="Download ZIP" arrow>
                        <IconButton 
                          onClick={async () => {
                            const shortenedUrl = await shortenUrl(item.file_path);
                            const url = item.file_path;
                            window.open(url, '_blank');
                            // window.open(shortenedUrl, '_blank');

                            incrementDownloadCount(item.id);
                          }}
                          sx={{ 
                            color: '#00A0DC',
                            '&:hover': { backgroundColor: '#C0EAEB' }
                          }}
                          disabled={!item.file_path}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {/* <TableCell>
                      <Tooltip title="Download DEB" arrow>
                        <IconButton 
                          onClick={() => {
                            handleDownloadDeb(item.id, item.package_name);
                            incrementDownloadCount(item.id);
                          }} 
                          style={styles.iconButton}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>

      <Dialog open={showJsonPopup} onClose={handleCloseJsonPopup} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="h6">Configuration JSON</Typography>
          <SyntaxHighlighter language="json" style={materialDark} showLineNumbers>
            {selectedJsonContent}
          </SyntaxHighlighter>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJsonPopup} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Modal open={showCodePopup} onClose={handleCloseCodePopup}>
        <Paper style={styles.codeModalPaper}>
          <Typography variant="h6" align="center" gutterBottom>
            Code 
          </Typography>
          <div style={styles.codeContainer}>
            <SyntaxHighlighter language="python" style={materialDark} showLineNumbers wrapLines>
              {selectedCodeContent}
            </SyntaxHighlighter>
          </div>
          <Button variant="contained" onClick={handleCloseCodePopup} style={styles.button}>
            Close
          </Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default CodeDownloadModal;
