// // src/pages/LoginPage.js
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Paper,
//   InputAdornment,
//   IconButton
// } from "@mui/material";
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// // import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import EmailIcon from "@mui/icons-material/Email";
// import LockIcon from "@mui/icons-material/Lock";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import "./loginPage.css"; // Ensure you create a corresponding CSS file
// import copilot from "../../assets/images/copilot.png"; // Import your image here
// import axios from "axios";
// import { useAuth } from "../../context/AuthContext";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#1aa1db", // ThingsBoard primary color
//     },
//     secondary: {
//       main: "#c0eaeb", // ThingsBoard secondary color
//     },
//   },
//   typography: {
//     h2: {
//       fontFamily: "Roboto, sans-serif",
//       fontWeight: 500,
//       color: "#333",
//     },
//     button: {
//       fontFamily: "Roboto, sans-serif",
//       fontWeight: 600,
//     },
//   },
// });

// const LoginPage = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const navigate = useNavigate();
//   const [error, setError] = useState("");
//   const { login } = useAuth();
//   const [showPassword, setShowPassword] = useState(false);

//   const handleClickShowPassword = () => setShowPassword((prev) => !prev);

//   // const [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
//   // let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)

//   // const handleLogin = async (event) => {
//   //   event.preventDefault();

//   //   const data = {
//   //     email: email,
//   //     password: password,
//   //   };

//   //   try {
//   //     const response = await axios.post('http://${process.env.REACT_APP_PUBLIC_URL}/api/login/', data);
//   //     console.log('User logged in successfully:', response.data);

//   //     const tokens = response.data;
//   //     console.log("tokens : ", tokens)
//   //     const decodedUser = jwtDecode(tokens.access);
//   //     console.log("decoded user : ", decodedUser)

//   //     // Store tokens and user info in local storage
//   //     localStorage.setItem('authTokens', JSON.stringify(tokens));
//   //     setAuthTokens(tokens);
//   //     setUser(decodedUser);

//   //     navigate('/chat');
//   //   } catch (error) {
//   //     console.error('Error logging in user:', error.response?.data || error.message);
//   //     setError('Invalid email or password');
//   //   }
//   // };

//   const handleLogin = async (event) => {
//     event.preventDefault();

//     const data = {
//       email: email,
//       password: password,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PUBLIC_URL}/api/login/`,
//         data,
//       );
//       console.log("User logged in successfully:", response.data);
//       // const { access, user_id } = response.data;
//       // localStorage.setItem('authToken', access);
//       // localStorage.setItem('userId', user_id);
//       // console.log("id, token :", access, user_id)

//       // Use the login function from the Auth Context
//       login(response.data);
//       navigate("/chat");
//     } catch (error) {
//       console.error(
//         "Error logging in user:",
//         error.response?.data || error.message,
//       );
//       setError("Invalid email or password");
//     }
//   };

//   const handleSignUp = () => {
//     navigate("/");
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" maxWidth="xs">
//         <Paper
//           className="login-box"
//           elevation={6}
//           sx={{ padding: 4, marginTop: 12 }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <div className="login-outer"></div>

//             <div className="logo">
//               <img
//                 src={copilot}
//                 alt="logo"
//                 style={{
//                   width: "100%",
//                   maxWidth: "200px",
//                   marginBottom: "16px",
//                 }}
//               />
//             </div>
//             {/* <img src={copilot} alt="logo" style={{ width: '100%', maxWidth: '200px', marginBottom: '16px' }} /> */}
//             {/* <Typography component="h5" variant="h6">
//               LogIn
//             </Typography> */}
//             <Box
//               className="newdesignlabel"
//               component="form"
//               onSubmit={handleLogin}
//               sx={{ mt: 1 }}
//             >
//               <TextField
//                 margin="normal"
//                 variant="standard"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 InputProps={{
//                   startAdornment: <EmailIcon position="start" />,
//                 }}
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 variant="standard"
//                 name="password"
//                 label="Password"
//                 type={showPassword ? 'text' : 'password'}
//                 id="password"
//                 autoComplete="current-password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 InputProps={{
//                   startAdornment: <LockIcon position="start" />,
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         edge="end"
//                       >
//                         {showPassword ? <Visibility /> : <VisibilityOff />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <Button
//                 className="login-button"
//                 type="submit"
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//               >
//                 Login
//               </Button>
//               <Typography
//                 className="signuptext"
//                 variant="body2"
//                 align="center"
//                 sx={{ mt: 2 }}
//               >
//                 Don't have an account?
//                 <Button color="primary" onClick={handleSignUp}>
//                   Sign Up
//                 </Button>
//               </Typography>
//               <Typography
//                 className="poweredby"
//                 style={{
//                   width: "100%",
//                   maxWidth: "200px",
//                   marginBottom: "16px",
//                 }}
//                 variant="caption"
//                 display="block"
//                 gutterBottom
//               >
//                 Powered by <b>CIMCON Digital</b>
//               </Typography>
//             </Box>
//           </Box>
//         </Paper>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default LoginPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Alert
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./loginPage.css"; // Ensure you create a corresponding CSS file
import copilot from "../../assets/images/copilot.png"; // Import your image here
import axios from "axios";
import { useAuth } from "../../context/AuthContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1aa1db", // ThingsBoard primary color
    },
    secondary: {
      main: "#c0eaeb", // ThingsBoard secondary color
    },
  },
  typography: {
    h2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      color: "#333",
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600,
    },
  },
});

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleLogin = async (event) => {
    event.preventDefault();
  
    const data = {
      email: email,
      password: password,
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/login/`,
        data,
      );
      console.log("User logged in successfully:", response.data);
  
      // Use the login function from the Auth Context
      login(response.data);
      navigate("/chat");
    } catch (error) {
      console.error(
        "Error logging in user:",
        error.response?.data || error.message,
      );
      setError("Invalid email or password");
    }
  };
  

  // const handleSignUp = () => {
  //   navigate("/");
  // };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    if (error) {
      setError("");
    }
  };

  const handleForgetPassword = () =>{
    navigate("/forget-password")
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Paper
          className="login-box"
          elevation={6}
          sx={{ padding: 4, marginTop: 12 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="login-outer"></div>

            <div className="logo">
              <img
                src={copilot}
                alt="logo"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  marginBottom: "16px",
                }}
              />
            </div>
            <Box
              className="newdesignlabel"
              component="form"
              onSubmit={handleLogin}
              sx={{ mt: 1 }}
            >
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              <TextField
                margin="normal"
                variant="standard"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={handleInputChange(setEmail)}
                InputProps={{
                  startAdornment: <EmailIcon position="start" />,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                variant="standard"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handleInputChange(setPassword)}
                InputProps={{
                  startAdornment: <LockIcon position="start" />,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="body2" align="right" sx={{ mt: 2, cursor: "pointer" }} onClick={handleForgetPassword}>
                {/* <Button color="primary" onClick={handleForgetPassword} sx={{marginLeft: 16, marginTop: -5 }}> */}
                  Forgot Password?
                {/* </Button> */}
              </Typography>
              <Button
                className="login-button"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              
              <Typography
                className="signuptext"
                variant="body2"
                align="center"
                sx={{ mt: 2 }}
              >
                Don't have an account?
                <Button color="primary" onClick={() => navigate("/")}>
                  Sign Up
                </Button>
              </Typography>
              <Typography
                className="poweredby"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  marginBottom: "16px",
                }}
                variant="caption"
                display="block"
                gutterBottom
              >
                Powered by <b>CIMCON Digital</b>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;


